#dalgoTpath {
  height: 100%;
  margin: 0 10px 0 10px;

  #customOverlay {
    width: 80px;
    height: 30px;
    background-color: white;
  }

  #dalgoT_info_table {
    width: 500px;
  }

  #dalgoPath_colorButton {
    display: flex;
    align-items: center;
    > button {
      padding: 3px 5px 3px 5px;
      margin: 2px;
      border-radius: 5px;
      font-weight: bold;
      background-color: white;
      border: 2px solid #66f;
      color: #66f;
    }
    .selected {
      background-color: #66f;
      color: white;
    }
  }

  #dalgoT_infoWindow {
    width: 200px;
    background-color: white;
    border: 1px solid black;
    padding: 5px;
    position: absolute;
    bottom: 25px;
    left: 10px;
    z-index: 99999;
  }

  #dalgoT_legend {
    display: flex;
    > div {
      display: flex;
      align-items: center;
      margin-left: 10px;
      > label {
        text-align: center;
      }
      > div {
        margin-left: 3px;
        width: 28px;
        height: 16px;
      }
    }
  }
}
