.container {
  /* max-width: 1500px; */
  max-width: auto;
  margin: 0 auto;
  padding: 30px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h1 {
  font-size: 24px;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

h2 {
  font-size: 20px;
  color: #555;
  margin-top: 20px;
}

.top-interests {
  background-color: #e3f2fd; 
  border: 1px solid #90caf9; 
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 20px; 
}

.top-interests h2 {
  color: #1976d2; 
}

.demo-button {
  padding: 10px 15px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-right: 10px;
}

button:hover {
  background-color: #0056b3;
}

.results {
  margin-top: 20px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
}

ol {
  padding-left: 20px; 
}

li {
  margin: 5px 0; 
  font-size: 16px;
  color: #444;
}

li > span {
  font-size: 19px;
}

.horizontal-list {
  display: flex; 
  list-style-type: none; 
  padding: 0; 
  margin: 0; 
  justify-content: center;
}

.horizontal-list li {
  margin-right: 100px; 
  font-size: 24px; 
  color: black; 
}

.horizontal-list li:last-child {
  margin-right: 0; 
}

.select-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  margin-bottom: 30px;
  justify-content: space-between; 
}

.checkboxGroup label {
  display: block;
  margin-bottom: 8px;
  font-size: 16px;
  color: #333;
}

.checkboxGroup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #f0f4f8; 
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ccc; 
}

.checkboxGroup input[type="checkbox"] {
  margin-right: 10px;
}

.checkboxGroup label {
  display: flex;
  align-items: center; 
  margin-bottom: 5px;
}

.checkboxGroup:last-child {
  margin-bottom: 0; 
}

select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  background-color: #fff;
  width: 100%;
  box-sizing: border-box; 
}

.adNotice {
  float: right;
  margin-top: 10px;
  margin-bottom: 30px;
  color:#555;
}

.gap {
  margin-top : 10px;
}

.sliderContainer {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

input[type="range"] {
  width: 80%;
  cursor: pointer;
}

.horizontal-sections {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  width: 100%; 
  margin: 30px 0;
}

.horizontal-section {
  flex: 1 1 calc(25% - 20px);
  max-width: 400px; 
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 15px;
  background-color: #fff;
  box-sizing: border-box; 
}

.horizontal-chart-section {
  width: 700px; 
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 15px;
  background-color: #fff;
  box-sizing: border-box;
}

.chart-container {
  width: 70%; 
  height: 300px; 
  padding: 20px; 
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); 
  background-color: #fff; 
  border-radius: 8px; 
  margin: auto;
}