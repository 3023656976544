.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5); // 반투명 검정색 배경
    z-index: 1000;
}

.modal-content {
    background-color: #fff; // 모달 내부 배경 색상 (흰색)
    padding: 24px; // Tailwind의 p-6
    border-radius: 8px; // Tailwind의 rounded-lg
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); // Tailwind의 shadow-lg
    max-width: 600px; // Tailwind의 max-w-[600px]
    width: 90vw; // Tailwind의 w-[90vw]
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px; // Tailwind의 mb-4
}

.modal-title {
    font-size: 1.5rem; // Tailwind의 text-2xl
    font-weight: bold; // Tailwind의 font-bold
}

.modal-body {
    margin-bottom: 24px; // 기존 스타일 유지

    ul {
        list-style: none; // 리스트 마커 제거
        padding: 0;

        li {
            padding: 8px 0; // 각 리스트 항목의 상하 패딩
            border-bottom: 1px solid #e2e8f0; // 항목 간 구분선

            &:last-child {
                border-bottom: none; // 마지막 항목에는 구분선 없음
            }
        }
    }
}
.modal-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 24px; // Tailwind의 mt-6
}

.button {
    background-color: #007bff;
    color: white;
    border: 1px solid #ffffff;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
        background-color: #0056b3;
        color: white;
    }
}

.separator {
    border-bottom: 1px solid #e2e8f0; // Tailwind의 border-bottom
    margin: 16px 0; // Tailwind의 mb-4
}

.dependency-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px; // Tailwind의 mb-4

    .name {
        font-weight: 500; // Tailwind의 font-medium
    }

    .version,
    .license {
        font-size: 14px; // Tailwind의 text-sm
        color: #6c757d; // Tailwind의 text-muted-foreground
        text-align: right;
    }
}