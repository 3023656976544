.container {
    display: flex;
    flex-direction: column;
    margin: 20px;
    font-family: 'Arial', sans-serif;
   

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
       
        h1 {
            color: #333;
        }

        .search {
            display: flex;
            align-items: center;

            input {
                width: 200px;
                height: 32px;
                padding: 0 10px;
                margin-left: 10px;
                border: 1px solid #ccc;
                border-radius: 4px;

                &::placeholder {
                    color: #aaa;
                }
            }
        }
    }

    .table-container {
        overflow-x: auto;
        border: 1px solid #ddd;

        table {
            width: 100%;
            border-collapse: collapse;

            th,
            td {
                text-align:center;
                font-size: medium;
                padding: 8px;
                border-right: 1px solid #ddd;
            }

            th {
                background-color: #f7f7f7;
            }

            tbody tr:hover {
                background-color: #ccd7e2;

                td.custom-column {
                    background-color: inherit; // 부모 tr의 배경색을 상속받도록 설정
                }
            }

            td.custom-column {
                padding: 8px;
                text-align: center;
                background-color: #f9f9f9; // 기본 배경색 설정
                border-radius: 4px;
            }

            button {
                padding: 6px 12px;
                border: none;
                background-color: #007bff;
                color: white;
                border-radius: 4px;
                cursor: pointer;

                &:hover {
                    background-color: #0056b3;
                }
            }
        }
    }
}