.daily-table {
  margin-left: 20px;
  > div {
    > div {
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: end;
      > div {
        margin: 0 10px 0 10px;
      }
    }
    > table {
      width: 100%;
    }
  }
}
