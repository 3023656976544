#dalgoTMonthlydashboard {
  height: 100%;
  margin: 0 10px 0 10px;

  > div:nth-child(1) {
    > select,
    button {
      box-sizing: border-box;
      width: 240px;
      height: 30px;
      padding: 0;
      border: 1px solid black;
      margin-right: 3px;
      font-size: 20px;
    }
  }

  > div:nth-child(2) {
    display: flex;
    > div:nth-child(1) {
      > div:nth-child(1) {
        display: flex;
        justify-content: space-between;
        width: 632px;
        margin: 3px 0 3px 0;
        > div {
          display: flex;
          > div,
          button {
            box-sizing: border-box;
            height: 30px;
            padding: 0;
            border: 1px solid black;
            font-size: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          > div {
            width: 120px;
          }
          > button {
            width: 30px;
          }
        }
        > button {
          box-sizing: border-box;
          height: 30px;
          border: 1px solid black;
          font-size: 18px;
          display: flex;
          align-items: center;
        }
      }
      > div:nth-child(2) {
        display: flex;
      }
    }
  }

  #calendar {
    width: 630px;
    border: 1px solid black;
    display: flex;
    flex-wrap: wrap;
    > span {
      box-sizing: border-box;
      width: 90px;
      height: 24px;
      border: 1px solid black;
      align-content: center;
    }
    > div {
      box-sizing: border-box;
      width: 90px;
      height: 100px;
      border: 1px solid black;
      padding: 3px;
      display: flex;
      flex-direction: column;
      background-color: rgb(193, 200, 255);
      > span:nth-child(1) {
        font-size: 20px;
        margin-bottom: 10px;
      }
    }
    > div.no_data {
      background-color: rgb(255, 174, 33);
    }
    > div.no_csv {
      background-color: rgb(255, 179, 179);
    }
    > div.no_date {
      background-color: rgb(255, 255, 255);
    }
  }

  #info {
    box-sizing: border-box;
    width: 240px;
    border: 2px solid black;
    margin-left: 20px;
    padding: 5px;
    > div:nth-child(1) {
      height: 50px;
      font-size: 1.5rem;
    }
    > div:nth-child(2) {
      height: 420px;
      > div {
        margin: 0 0 10px 0;
      }
    }
    button {
      box-sizing: border-box;
      width: 100%;
      height: 30px;
      padding: 0;
      border: 1px solid black;
      margin-right: 3px;
      font-size: 20px;
    }
  }
}
