.loginForm {
    display: flex;
    flex-direction: column;
    width: 250px;
    margin: auto;
}
.loginForm input,
.loginForm button {
  margin-bottom: 20px;
  height: 30px;

}
.loginFormTitle {
    margin-top: 100px;
    margin-bottom: 30px;
}

.errorMessage {
    color: red;
    margin-bottom: 10px;
}