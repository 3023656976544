/* versionManager.scss */

.file-tree-container {
  display: flex;
  height: 100vh;
}

.file-tree {
  width: 33%;
  background-color: #f7f7f7;
  padding: 16px;
  overflow-y: auto;
}

.file-viewer {
  flex: 1;
  padding: 16px;
  overflow-y: auto;
  background-color: #ffffff;
}

.tree-node {
  cursor: pointer;
  &.selected {
    font-weight: bold;
  }
  span {
    display: inline-block;
    margin: 2px 0;
  }
}

.tree-node-children {
  margin-left: 16px;
}

.file-content {
  background-color: #f1f1f1;
  padding: 16px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.tree-node {
  user-select: none;

}

.tree-node span {
  -webkit-user-drag: none;
  
}

.progress-bar {
  width: 100%;
  background-color: #f3f3f3;
  border-radius: 5px;
  margin: 10px 0;
  overflow: hidden;
}

.progress-bar-fill {
  height: 20px;
  background-color: #4caf50;
  text-align: center;
  line-height: 20px;
  color: white;
  transition: width 0.2s;
}

/* 우측 하단 고정 스타일 */
.progress-bar-fixed {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 300px;
  background-color: #f3f3f3;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.progress-bar-fill {
  height: 20px;
  background-color: #4caf50;
  text-align: center;
  line-height: 20px;
  color: white;
  transition: width 0.2s;
}


.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.spinner-container {
  height: 50px; // 스피너 높이를 고정
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  border: 5px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading-text {
  text-align: center;
  color: white;
  font-size: 18px;
  margin-top: 20px; // 텍스트와 스피너 사이 간격 조정
}