.inquiry-container {
  padding: 10px;

  h1 {
    text-align: center;
    margin-bottom: 20px;
  }

  .input-field {
    border: 1px solid #ccc;
    border-radius: 5px;
    height: 25px;
    margin: 10px 5px;
  }

  select {
    margin: 10px 5px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  button {
    margin: 5px;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    cursor: pointer;

    &:hover {
      background-color: #0056b3;
    }
  }

  .date-range-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0;

    .inquiry-datepicker {
      width: 200px;
      padding: 5px;
      border: 1px solid #ccc;
      border-radius: 5px;
      margin: 0 5px;
    }

    button {
      margin: 0 5px;
    }
  }

  .file-upload {
    margin: 10px 0;
    display: flex;
    align-items: center;

    input[type="file"] {
      margin-right: 10px;
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;

    th,
    td {
      padding: 10px;
      border: 1px solid #ddd;
      text-align: center;
    }

    th {
      background-color: #f2f2f2;
    }

    .table-row-hover tbody tr:hover {
      background-color: #f5f5f5;
    }

    button {
      background-color: #28a745;
      width: 50%;

      &:hover {
        background-color: #218838;
      }
    }
  }
}

.loading-spinner {
  font-size: 18px;
  text-align: center;
  padding: 20px;
}

.pagination-controls {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination-controls button {
  margin: 0 5px;
  padding: 5px 10px;
}

.log-row {
  position: relative;
  width: 100%; 
}

.log-row:hover .error-ratio-tooltip {
  display: block;
  background-color: #f8d7da;
  border-color: #f5c6cb; 
  color: #721c24; 
}

.hover-error-ratio .error-ratio-tooltip {
  display: none;
  position: absolute;
  top: 100%;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 3px;
  white-space: nowrap;
  z-index: 100;
  color: #333; 
  text-align: center; 
}

.csv-row {
  position: relative;
  width: 100%;
}

.csv-row:hover .file-line-tooltip {
  display: block;
  background-color: rgb(218, 218, 218);
  border-color: rgb(185, 185, 185);
  color: black;
}

.no-log-date { 
  background-color: #ffcccc !important; 
  color: #a00;
}

.has-log-date {
  background-color: #ccffcc !important; 
  color: #0a0; 
}

.info-message {
  margin-top: 10px;
  padding: 10px;
  background-color: #f0f8ff; 
  color: #333; 
  border-left: 4px solid #007bff; 
  border-radius: 4px; 
  font-size: 15px; 
  line-height: 1.5;
}