#adddi-light-registration-table {
  position: relative;
  margin-left: 20px;

  > #modal {
    width: 400px;
    border: 1px solid black;
    background-color: #fff;
    position: absolute;
    left: calc(50% - 200px);
    top: 200px;
    z-index: 99;
    > div:nth-child(1) {
      height: 24px;
      display: flex;
      justify-content: flex-end;
    }
    > div:nth-child(2) {
      height: 150px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      > div:nth-child(1) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      > div:nth-child(2) {
        display: flex;
        justify-content: center;
        align-items: center;
        > span {
          font-size: 1.2rem;
        }
        > input,
        button {
          box-sizing: border-box;
          width: 72px;
          height: 36px;
          margin: 0 0 0 3px;
          font-size: 1.2rem;
        }
        .click_o {
          border: 1px solid black;
          background-color: #eee;
        }
        .click_x {
          border: 1px solid black;
          background-color: #aaa;
        }
      }
      > div:nth-child(3) {
        color: red;
      }
    }
  }

  button {
    margin: 0 3px;
  }

  /* hoverText */
  .hovertext {
    position: relative;
    border-bottom: 1px dotted black;
  }

  .hovertext:before {
    content: attr(data-hover);
    visibility: hidden;
    opacity: 0;
    width: max-content;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px 5px;
    transition: opacity 1s ease-in-out;

    position: absolute;
    z-index: 1;
    left: 0;
    top: 110%;
  }

  .hovertext:hover:before {
    opacity: 1;
    visibility: visible;
  }
}
