// 색상 변수 설정
$background: #ffffff; // 배경 색상
$foreground: #1a1a1a; // 전경 색상
$border-color: #e0e0e0; // 테두리 색상
$input-bg: #f7f7f7; // 인풋 필드 배경 색상
$input-border: #cccccc; // 인풋 필드 테두리 색상
$button-bg: #f7f7f7; // 버튼 배경 색상
$button-border: #d1d5db; // 버튼 테두리 색상
$checkbox-bg: #ffffff; // 체크박스 배경 색상
$checkbox-border: #6b7280; // 체크박스 테두리 색상
$checkbox-checked-bg: #1a1a1a; // 체크된 체크박스 배경 색상
$checkbox-checked-border: #1a1a1a; // 체크된 체크박스 테두리 색상

// 전체 적용 스타일
body {
    font-family: Arial, sans-serif;
    background-color: $background;
    color: $foreground;
}

.dropdown {
    position: relative;
    display: inline-block;
    font-family: inherit;

    button {
        background-color: $button-bg;
        border: 1px solid $button-border;
        border-radius: 0.5rem;
        padding: 0.5rem 1rem;
        font-size: 0.875rem;
        color: $foreground;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        cursor: pointer;

        svg {
            width: 1rem;
            height: 1rem;
        }
    }

    .dropdown-menu {
        position: absolute;
        top: 100%;
        left: -240px;
        background-color: $background;
        border: 1px solid $border-color;
        border-radius: 0.5rem;
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
        margin-top: 0.5rem;
        padding: 1rem;
        width: 300px;

        input[type="text"] {
                background-color: $input-bg;
                border: 1px solid $input-border;
                border-radius: 0.25rem;
                padding: 0.5rem;
                font-size: 0.875rem;
                width: calc(100% - 20px); // 전체 너비에서 패딩 및 여백을 뺀 너비
                margin: 0 auto 1rem auto; // 좌우 중앙 정렬 및 아래쪽 마진 추가
                display: block; // block으로 설정하여 중앙 정렬 유지
            }

                .checkbox-list {
                    display: flex;
                    flex-direction: column;
                    gap: 0.5rem;
                    max-height: 200px; // 원하는 최대 높이로 설정
                    overflow-y: auto; // 내용이 많을 경우 스크롤 생성
        
                    label {
                        display: flex;
                        align-items: center;
                        gap: 0.5rem;
        
                        input[type="checkbox"] {
                            appearance: none;
                            background-color: $checkbox-bg;
                            border: 1px solid $checkbox-border;
                            border-radius: 0.25rem;
                            width: 1rem;
                            height: 1rem;
                            cursor: pointer;
        
                            &:checked {
                                background-color: $checkbox-checked-bg;
                                border-color: $checkbox-checked-border;
                            }
                        }
        
                        span {
                            font-size: 0.875rem;
                            color: $foreground;
                        }
                    }
                }
    }
}