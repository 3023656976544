.input-table {
  // margin-top: 200px;
  // width: 90%;
  // // height: 10%;
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
  width: fit-content;
  margin: 100px auto 0px auto;
  display: block;
  position: relative; /* 추가된 부분 */
  > div {
    width: 700px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    position: relative; /* 추가된 부분 */
  }
}

.react-datepicker__tab-loop {
  position: absolute;
  z-index: 9999; /* 필요 시 추가된 부분 */
}

.buttonDiv {
  text-align: center;

  > button{
    width: 15%;
    margin-top: 30px;
    margin-right: 20px;
  }
}

select {
  width: 153px;
}

.delete-button {
  position: absolute;
  top: 0;
  right: 0;
  background: red;
  color: white;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.image-preview,
.video-preview {
  position: relative;
  display: flex;
}

.image-preview img,
.video-preview video {
  width: 200px;
  height: 200px;
  margin-right: 10px;
}