#dalgoTdashboard2 {
  height: 100%;
  margin: 10px;
  #title {
    font-size: 24px;
  }
  table {
    max-height: 0;
    margin-bottom: 20px;
  }
}
