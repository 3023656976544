.file-conflict-dialog {
    padding: 1rem; // 16px
    background-color: #ffffff; // bg-white
    border-radius: 0.5rem; // rounded-lg
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); // shadow-lg
    max-width: 32rem; // max-w-lg

    .dialog-header {
        font-size: 1.125rem; // text-lg
        font-weight: bold; // font-bold
        color: #1a202c; // text-gray-900
        margin-bottom: 1rem; // space-y-4
    }

    .description {
        font-size: 0.875rem; // text-sm
        color: #718096; // text-gray-600
    }

    .button-container {
        display: flex;
        justify-content: flex-end;
        gap: 0.5rem; // space-x-2

        button {
            padding: 0.5rem 1rem; // px-4 py-2
            border-radius: 0.375rem; // rounded
            font-weight: bold;
            cursor: pointer;
            transition: background-color 0.3s;

            &.overwrite {
                background-color: #e53e3e; // bg-red-500
                color: #ffffff; // text-white

                &:hover {
                    background-color: #c53030; // hover:bg-red-700
                }
            }

            &.update-version {
                background-color: #3182ce; // bg-blue-500
                color: #ffffff; // text-white

                &:hover {
                    background-color: #2b6cb0; // hover:bg-blue-700
                }
            }

            &.cancel {
                background-color: #e2e8f0; // bg-gray-300
                color: #2d3748; // text-gray-800

                &:hover {
                    background-color: #cbd5e0; // hover:bg-gray-400
                }
            }
        }
    }
}