#weekly-table {
  margin-left: 20px;
  > div {
    > div {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      > div {
        text-align: center;
        margin: 0 10px 0 10px;
      }
      > button {
        height: 24px;
        width: 20px;
        border: 1px black solid;
        background-color: white;
        padding: 3px;
      }
    }
  }
  .padding0 {
    > td {
      padding: 0px;
    }
  }
}
