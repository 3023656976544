#schedule-detail-table {
  margin-left: 20px;
  height: 100%;
  > th {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .Schedule {
    width: 100%;
    height: calc(100% - 150px);
    display: flex;
    overflow: scroll;
  }

  /* hoverText */
  .hovertext {
    position: relative;
    border-bottom: 1px dotted black;
  }

  .hovertext:before {
    content: attr(data-hover);
    visibility: hidden;
    opacity: 0;
    width: max-content;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px 5px;
    transition: opacity 1s ease-in-out;

    position: absolute;
    z-index: 1;
    left: 0;
    top: 110%;
  }

  .hovertext:hover:before {
    opacity: 1;
    visibility: visible;
  }
}

#schedule-detail-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  select {
    width: 240px;
    font-size: 18px;
  }
  button,
  input {
    font-size: 20px;
    margin: 0px;
    padding: 0px;
  }
  input {
    margin: 0 4px 0 4px;
  }
  button {
    width: 30px;
    height: 30px;
  }

  .color {
    display: flex;
    align-items: end;
    p {
      margin: 0px;
    }
    div {
      width: 30px;
      height: 15px;
      border: 1px solid black;
      margin: 0 10px 0 3px;
    }
  }
  //   input {
  //     margin-right: 5px;
  //   }
  //   button {
  //     margin-right: 5px;
  //   }
}
