.required {
  color: red; /* Set the color as needed */
  margin-left: 5px; /* Adjust the spacing as needed */
}

.addButton {
  margin-bottom: 10px;
  margin-right: 10px;
  width: 150px;
}

.syncButton {
  width: 150px;
  margin-bottom: 20px;
  margin-right: 10px;
}

.excelButton {
  width: 150px;
  margin-bottom: 10px;
}

.searchInput {
  margin-bottom: 10px;
}

.searchInput select, .searchInput input {
  margin-right: 10px;
}

.searchInput span {
margin-right: 5px;
}

.list-container {
  overflow-x: auto;
  max-width: 100%; /* 테이블이 가득 차도록 설정 */
  max-height: 80%;
}

.datepicker {
  margin-bottom: 10px;
  display: flex;
  > div {
    display: flex;
    margin-right: 15px;
    > label {
      margin-right: 5px;
    }
  }
}

.largeTextArea {
  width: 600px;
  height: 50px;
}

.loading {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}


/* 선택적으로 테이블의 너비를 지정할 수 있습니다. */
table {
  width: 80%; /* 테이블이 가득 차도록 설정 */
  white-space: nowrap; /* 테이블의 셀이 줄 바꿈되지 않도록 설정 */
}

/* 선택적으로 테이블 헤더의 너비를 조정할 수 있습니다. */
th {
  min-width: 100px; /* 각 열의 최소 너비 설정 */
  padding: 8px; /* 선택적으로 셀의 여백을 조정할 수 있습니다. */
}