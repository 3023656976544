#dalgoTdashboard {
  height: 100%;
  margin: 0 10px 0 10px;
  display: flex;

  > div:nth-child(1) {
    width: 70%;
    > div:nth-child(1) {
      height: 100px;
      margin-top: 20px;
    }
    > div:nth-child(2) {
      height: calc(100% - 130px);
    }
  }

  > div:nth-child(2) {
    width: 30%;
    min-width: 400px;
    margin-left: 10px;
  }

  > div > div > input,
  select {
    box-sizing: border-box;
    height: 30px;
    padding: 0;
    border: 1px solid black;
    margin-right: 3px;
    font-size: 20px;
  }

  select {
    width: 320px;
  }

  #customOverlay {
    width: 80px;
    height: 30px;
    background-color: white;
  }

  #dalgoT_info_table {
    width: 500px;
  }

  #dalgoT_colorButton {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 99999;
    > button {
      padding: 3px 5px 3px 5px;
      margin: 2px;
      border-radius: 5px;
      font-weight: bold;
      background-color: white;
      border: 2px solid #66f;
      color: #66f;
    }
    .selected {
      background-color: #66f;
      color: white;
    }
  }

  #dalgoT_infoWindow {
    width: 200px;
    background-color: white;
    border: 1px solid black;
    padding: 5px;
    position: absolute;
    bottom: 25px;
    left: 10px;
    z-index: 99999;
  }

  #dalgoT_legend {
    display: flex;
    > div {
      display: flex;
      align-items: center;
      margin-left: 10px;
      > label {
        text-align: center;
      }
      > div {
        margin-left: 3px;
        width: 28px;
        height: 16px;
      }
    }
  }

  #dalgoT_scroll {
    height: calc(100% - 120px);
    overflow-y: scroll;
    margin-top: 120px;
    h2 {
      margin: 0;
    }
    table {
      margin-bottom: 30px;
    }
  }

  #range {
    input {
      width: 100px;
    }
    button {
      margin-left: 5px;
    }
    p {
      margin: 0px;
      font-size: 16px;
    }
  }
}
