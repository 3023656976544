#ad-dashboard-table {
  margin-left: 20px;
  .ad_box {
    display: flex;
  }
  .ad {
    margin: 0 5px 0 5px;
    padding: 3px;
    border: 1px solid black;
    color: white;
  }
  .ad_0 {
    background-color: red;
  }
  .ad_1 {
    background-color: blue;
  }

  /* hoverText */
  .hovertext {
    position: relative;
    border-bottom: 1px dotted black;
  }

  .hovertext:before {
    content: attr(data-hover);
    visibility: hidden;
    opacity: 0;
    width: max-content;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px 5px;
    transition: opacity 1s ease-in-out;

    position: absolute;
    z-index: 1;
    left: 0;
    top: 110%;
  }

  .hovertext:hover:before {
    opacity: 1;
    visibility: visible;
  }
}
