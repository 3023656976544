.sidebar {
    width: 150px;
    max-height: 100vh; 
    overflow-y: auto; 
    background: rgb(255, 255, 255);
    color: black;
    text-align: left;
    box-shadow: 3px 0 10px rgba(0, 0, 0, 0.1);
    transition: width 0.3s ease;
    border: 1px solid blanchedalmond;
  }
  
  .sidebar.expanded {
    width: 260px;
  }

  
  
  .sidebar .menu-item {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #ffffff;
    text-decoration: none;
    transition: all 0.3s ease;
    border-bottom: 1px solid #2c3e50;
  }
  
  
  .sidebar .menu-item:hover {
    background-color: #34495e;
    color: #ecf0f1;
    transform: scale(1.05);
  }
  
  .sidebar .submenu {
    font-size: 14px;
    background-color: rgb(241, 244, 245);
    padding: 10px 20px;
    border-left: 4px solid gray;
    margin: 5px 0;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  // .sidebar .submenu:hover {
  //   // background-color: #dadfdf;

  //   // color: #ecf0f1;
  // }

  // .submenu:hover {
  //   // background-color: #dadfdf;
  // }
  
  .sidebar .submenu i {
    margin-right: 10px;
    font-size: 16px;
    transition: transform 0.3s ease;
  }
  
  .sidebar .submenu:hover i {
    transform: rotate(10deg);
  }

  
  .sidebar .active i {
    transform: rotate(360deg);
  }
  
  
  .submenu-content {
    display: none;
    padding-left: 40px;
    transition: max-height 0.3s ease;
  }
  
  .submenu.open .submenu-content {
    display: block;
    max-height: 200px;
  }
  
  .sidebar .sidebar-header {
    padding: 20px;
    background-color: #34495e;
    text-align: center;
    color: #ecf0f1;
    font-size: 18px;
    border-bottom: 1px solid #2c3e50;
  }
  
  .sidebar .sidebar-header:hover {
    background-color: #2c3e50;
  }
  
  // .sidebar-item i, .submenu i {
  //   color: #ecf0f1;
  //   transition: color 0.3s ease;
  // }

  
  .submenu-icon {
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .submenu-icon:hover {
    transform: rotate()
  }