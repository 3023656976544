.title {
  text-align: center;
  margin-bottom: 20px;
}

.date-filters {
  display: flex;
  gap: 15px;
  justify-content: center;
  margin-bottom: 20px;
}

.filter-label {
  display: flex;
  flex-direction: column;
  font-weight: bold;
}

.date-input {
  margin-top: 5px;
}

.controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.control-group {
  display: flex;
  justify-content: center; 
  align-items: center;
  gap: 15px;
  width: 100%;
  max-width: 600px;
}

.control-label {
  font-weight: bold;
  margin: 0;
  width: 120px; 
  text-align: right; 
  margin-right: 20px;
}

.media-input,
.graph-type-select {
  flex: 1;
  max-width: 200px; 
}

.metric-selector {
  position: relative;
  flex: 1;
  max-width: 200px; 
}

.dropdown-toggle {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  cursor: pointer;
  text-align: left;
}

.metric-options {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid #ccc;
  background-color: #fff;
  z-index: 10;
  width:300px;
}

.metric-option {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.metric-option:last-child {
  border-bottom: none;
}

.metric-option input {
  margin-right: 10px;
}

.loading {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}

.error {
  color: red;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}
