.dropdown {
    position: relative;
    display: inline-block;
    margin-bottom: 15px;

    button {
        background: white;
        border: 1px solid #ccc;
        padding: 8px 16px;
        cursor: pointer;

        &:hover {
            background-color: #f0f0f0;
        }
    }

    .dropdown-menu {
        position: absolute;
        background-color: #fff;
        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
        max-height: 200px; // 원하는 최대 높이로 설정
        overflow-y: auto; // 내용이 많을 경우 스크롤 생성

        .dropdown-item {
            color: black;
            padding: 12px 16px;
            text-align: left;
            display: block;
            border: none;
            background: none;
            width: 100%;

            &:hover {
                background-color: #f1f1f1;
            }
        }
    }
}