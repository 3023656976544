#version {
  height: 100%;
  margin: 10px;

  #modal {
    width: 400px;
    border: 1px solid black;
    background-color: #fff;
    position: absolute;
    left: calc(50% - 200px);
    top: 30%;
    padding: 5px;
    z-index: 99;
    > div:nth-child(1) {
      height: 24px;
      display: flex;
      justify-content: flex-end;
    }
  }
  #title {
    font-size: 24px;
  }
  #option {
    max-width: 80%;
    > div {
      display: flex;
      margin-bottom: 5px;
      > select {
        width: 30%;
      }
      > button {
        width: 40px;
        margin-left: 5px;
      }
    }
  }
  table {
    max-height: 0;
    margin-top: 20px;
    button {
      margin-left: 5px;
    }
  }
}
