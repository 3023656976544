// .container {
//     display: flex;
//     flex-direction: column;
//     margin: 20px;
//     font-family: 'Arial', sans-serif;

//     .header {
//         display: flex;
//         justify-content: space-between;
//         align-items: center;
//         margin-bottom: 20px;

//         h1 {
//             color: #333;
//         }

//         .search {
//             display: flex;
//             align-items: center;

//             input {
//                 width: 200px;
//                 height: 32px;
//                 padding: 0 10px;
//                 margin-left: 10px;
//                 border: 1px solid #ccc;
//                 border-radius: 4px;

//                 &::placeholder {
//                     color: #aaa;
//                 }
//             }
//         }
//     }

//     .table-container {
//         overflow-x: auto;
//         border: 1px solid #ddd;

//         table {
//             width: 100%;
//             border-collapse: collapse;
            
//             th,
//             td {
//                 text-align: left;
//                 padding: 8px;
//                 border-right: 1px solid #ddd;
//                 &:last-child {
//                     text-align: center;
//                     border: none;
//                     border-bottom: 1px solid
//                 }
//             }

//             th {
//                 background-color: #f7f7f7;
                
//             }

//             tbody tr:hover {
//                 background-color: #f0f0f0;
//             }

//             td:last-child {
//                 display: flex;
//                 justify-content: center;
//                 align-items: center;
//                 height: 100%;
          
            
//             }

//             button {
//                 padding: 6px 12px;
//                 border: none;
//                 background-color: #007bff;
//                 color: white;
//                 border-radius: 4px;
//                 cursor: pointer;

//                 &:hover {
//                     background-color: #0056b3;
//                 }
//             }
//         }
//     }
// }


// MediaVersionHistory.module.scss

.header {
    display: flex;
    align-items: center;

    .backButton {
        background-color: #007bff; // 버튼 배경색
        color: white; // 텍스트 색상
        border: none; // 테두리 제거
        padding: 6px 14px; // 패딩 조정
        text-align: center; // 텍스트 정렬
        text-decoration: none; // 텍스트 데코레이션 제거
        display: inline-block; // 인라인 블록으로 설정
        font-size: 16px; // 폰트 크기
        margin: 4px 2px; // 마진
        cursor: pointer; // 커서 모양 변경
        border-radius: 5px; // 둥근 모서리
        transition: background-color 0.3s; // 배경색 전환 효과

        &:hover {
            background-color: #0056b3; // 호버 시 배경색
        }
    }

    h1 {
        margin-left: 20px; // 제목과 버튼 간의 간격 조정
    }
}