header {
  border-bottom: 1px solid #ccc;
  height: 100px;
}

main {
  width: 100vw;
  height: calc(100vh - 100px);
  display: flex;
  text-align: justify;
}

#mainDiv {
  width: calc(100vw - 150px);
  height: 100%;
  // display: flex;
  justify-content: center;
  overflow-y: scroll;
}

#mainDiv table {
  text-align: center;
}

#mainDiv tr {
  height: 36px;
}

.profile-container {
  display: flex;
  align-items: center;
}

.profile-info {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}

.profile-icon {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: #f1f1f1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
}

.profile-name {
  margin-right: 1rem;
}

.logout-button {
  background-color: #ff4d4d;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.logout-button:hover {
  background-color: #e63946;
}

.userDiv {
  display: flex;
  justify-content: right;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #dedede;
}

.userDiv button {
  margin-left: 10px;
}

.top-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background-color: #282c34;
  color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.logo {
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 1.5rem;
  color: #61dafb;
}

.sidebar {
  background-color: #333;
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.sidebar.expanded {
  width: 300px;
}

.sidebar-item {
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.sidebar-item:hover {
  font-weight: bold;
}
