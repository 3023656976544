/* 백그라운드 오버레이 */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* 반투명 검정색 */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    /* 다른 요소 위에 오도록 설정 */
}

/* 메모 카드 */
.memo-card {
    width: 100%;
    max-width: 40rem;
    display: flex;
    flex-direction: column;
    border: 1px solid #e2e8f0;
    border-radius: 0.375rem;
    background-color: #ffffff;
    z-index: 1000;
    /* 백그라운드 오버레이 위에 오도록 설정 */
}

.memo-card__header {
    padding: 1rem;
    border-bottom: 1px solid #e2e8f0;
}

.memo-card__title {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 0.25rem;
}

.memo-card__descriptixon {
    margin-top: 10px ;
    font-size: 0.875rem;
    color: #718096;
}

.memo-card__content {
    padding: 1rem;
    flex-grow: 1;
}

.memo-card__textarea {
    width: 98%;
    min-height: 100px;
    padding: 0.5rem;
    border-radius: 0.375rem;
    border: 1px solid #cbd5e0;
}

.memo-card__footer {
    display: flex;
    justify-content: flex-end;
    padding: 1rem;
    border-top: 1px solid #e2e8f0;
}

.memo-card__button {
    padding: 0.5rem 1rem;
    background-color: #3182ce;
    color: #ffffff;
    border-radius: 0.375rem;
    border: none;
    cursor: pointer;
    margin-left: 0.5rem;
    /* 버튼 간 간격 추가 */
}

.memo-card__button:hover {
    background-color: #2b6cb0;
}