@mixin iphone {
  @media (min-width: 0px) and (max-width: 660px) {
    @content;
  }
}

#statusLogList {
  #header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    > div:nth-child(1) {
      box-sizing: border-box;
      width: 90%;
      max-width: 800px;
      padding: 10px 0;
      background-color: #ddd;
      font-size: 20px;
      @include iphone {
        display: flex;
        flex-direction: column;
      }
    }
    > div:nth-child(2) {
      box-sizing: border-box;
      margin: 5px 0px;
      width: 90%;
      max-width: 800px;
      height: 50px;
      padding: 5px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      > div:nth-child(1) {
        text-align: left;
        font-weight: 800;
        @include iphone {
          font-size: 16px;
          display: flex;
          flex-direction: column;
        }
      }
      > div:nth-child(2) {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        > div {
          margin-left: 10px;
          @include iphone {
            margin-left: 5px;
            > select {
              font-size: 14px;
            }
          }
        }
      }
    }
    > div:nth-child(3) {
      box-sizing: border-box;
      margin: 5px 0px;
      background-color: #ddd;
      border-radius: 5px;
      padding: 20px;
      width: 90%;
      max-width: 800px;
      height: 130px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      > div:nth-child(1) {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: left;
        > div:nth-child(1) {
          @include iphone {
            font-size: 14px;
          }
          > div:nth-child(1) {
            font-weight: 600;
          }
          > div:nth-child(2) {
            color: #555;
          }
        }
        > div:nth-child(2) {
          display: flex;
          align-items: flex-end;
          > button {
            height: 40px;
            border: 1px solid black;
            border-radius: 8px;
            font-size: 16px;
            padding: 0 10px;
            margin-right: 10px;
            @include iphone {
              height: 30px;
              font-size: 14px;
            }
          }
          > button:hover {
            background-color: #ccc;
          }
          > span {
            font-size: 14px;
            margin-left: 5px;
          }
        }
      }
      > div:nth-child(2) {
        width: 160px;
        height: 100%;
        display: flex;
        flex-direction: column;
        @include iphone {
          width: 140px;
        }
        > div:nth-child(1) {
          height: 30%;
          text-align: center;
          font-weight: 600;
        }
        > div:nth-child(2) {
          height: 70%;
          display: flex;
          > div {
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            > span {
              font-size: 16px;
              font-weight: 600;
            }
            > div {
              font-size: 30px;
              font-weight: 800;
              @include iphone {
                font-size: 28px;
              }
            }
          }
        }
      }
    }
  }

  #body {
    display: flex;
    flex-direction: column;
    align-items: center;
    > div {
      box-sizing: border-box;
      margin: 5px 0px;
      border: 1px solid black;
      border-radius: 5px;
      padding: 0px 10px;
      width: 90%;
      max-width: 800px;
      @include iphone {
        padding: 0px 0px;
      }
      > div:nth-child(1) {
        height: 156px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include iphone {
          height: 120px;
        }
        > div:nth-child(1) {
          margin-left: 20px;
          width: calc(100% - 230px);
          @include iphone {
            width: calc(100% - 140px);
          }
          > div {
            text-align: left;
          }
          > div:nth-child(1) {
            font-weight: bold;
            white-space: nowrap;
            @include iphone {
              font-size: 14px;
            }
          }
          > div:nth-child(2) {
            font-size: 32px;
            letter-spacing: 10px;
            font-weight: bold;
            @include iphone {
              font-size: 20px;
              letter-spacing: 5px;
            }
          }
          > div:nth-child(n + 3) {
            font-size: 14px;
            color: #888;
            @include iphone {
              font-size: 12px;
            }
          }
        }
        > div:nth-child(2) {
          width: 160px;
          height: 90%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          > div:nth-child(1) {
            font-size: 36px;
            font-weight: 600;
          }
          > div:nth-child(2) {
            font-size: 14px;
            color: #888;
          }
          @include iphone {
            width: 80px;
            > div:nth-child(1) {
              font-size: 30px;
            }
            > div:nth-child(2) {
              font-size: 12px;
            }
          }
        }
        > div:nth-child(3) {
          width: 40px;
          height: 90%;
          border-left: 1px solid black;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 24px;
        }
      }
      > div:nth-child(2) {
        max-width: 800px;
        border-top: 1px solid black;
        padding: 10px 0;
        > div {
          display: flex;
          justify-content: space-between;
          > div {
            text-align: left;
          }
          > div:nth-child(1) {
            width: 160px;
          }
          > div:nth-child(2) {
            width: 100px;
          }
          > div:nth-child(3) {
            width: calc(100% - 260px);
          }
        }
      }
    }
  }

  .color-1 {
    color: #cc0000;
  }
  .color-0 {
    color: #3e9112;
  }

  .hover-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  /* hoverText */
  .hovertext {
    position: relative;
  }

  .hovertext:before {
    content: attr(data-hover);
    visibility: hidden;
    opacity: 0;
    width: max-content;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px 5px;
    transition: opacity 1s ease-in-out;

    position: absolute;
    z-index: 1;
    left: 0;
    top: 110%;
  }

  .hovertext:hover:before {
    opacity: 1;
    visibility: visible;
  }
}
