#schedule-table {
  margin-left: 20px;
  > th {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .O {
    background-color: blue;
    width: 10px;
    height: 10px;
  }
  .X {
    background-color: red;
  }
  .padding0 {
    > td {
      padding: 0px;
    }
  }
}

.schedule-table {
  width: 100%;
  height: calc(100vh - 180px);
  overflow: scroll;
}

.color {
  display: flex;
  justify-content: center;
  align-items: end;
  p {
    margin: 0px;
  }
  div {
    width: 30px;
    height: 15px;
    margin: 0 10px 0 3px;
  }
}
