table {
  overflow-x: auto;
  max-width: 100%; /* 테이블이 가득 차도록 설정 */
  max-height: 80%;
  border-collapse: collapse;
  font-size: 20px;
  }
table,
th,
td {
  border: 1px solid;
  padding: 5px;
}
td {
  text-align: center;
}